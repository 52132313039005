<template>
  <div
    class="min-h-screen"
    :style="{
      'background-image': `url('/img/background-guest-open-task.svg')`
    }"
  />
</template>

<script>
export default {};
</script>

<style></style>
